export default {
    simple_english_words : ["about", "after", "among", "under", "other",
                            "while", "where", "again", "still", "there",
                            "north", "south", "birth", "blood", "brass",
                            "bread", "burst", "cause", "chalk", "cloth",
                            "cough", "crack", "crime", "crush", "death",
                            "doubt", "drink", "earth", "error", "field",
                            "fight", "flame", "force", "front", "fruit",
                            "glass", "grain", "grass", "group", "guide",
                            "laugh", "light", "limit", "linen", "metal",
                            "money", "month", "music", "night", "noise",
                            "offer", "order", "owner", "paint", "paper",
                            "paste", "peace", "place", "plant", "point",
                            "power", "price", "print", "prose", "range",
                            "scale", "sense", "shade", "shake", "shame",
                            "shock", "sleep", "slope", "smash", "smell",
                            "smile", "smoke", "sound", "space", "stage",
                            "start", "steam", "steel", "stone", "story",
                            "sugar", "taste", "thing", "touch", "trade",
                            "trick", "twist", "waste", "water", "woman",
                            "wound", "angle", "apple", "basin", "berry",
                            "blade", "board", "brain", "brake", "brick",
                            "brush", "chain", "chest", "clock", "cloud",
                            "drain", "dress", "floor", "frame", "heart",
                            "horse", "house", "knife", "match", "mouth",
                            "plane", "plate", "screw", "sheep", "shelf",
                            "shirt", "skirt", "snake", "spade", "spoon",
                            "stamp", "stick", "store", "table", "thumb",
                            "tooth", "train", "watch", "wheel", "angry",
                            "black", "brown", "cheap", "chief", "clean",
                            "clear", "early", "first", "great", "happy",
                            "ready", "right", "round", "sharp", "stiff",
                            "sweet", "thick", "tight", "tired", "young",
                            "awake", "cruel", "dirty", "false", "green",
                            "loose", "rough", "short", "small", "solid",
                            "white", "wrong"],
    deck : ('E'.repeat(6)+
            'AIORST'.repeat(4)+
            'UNLHDC'.repeat(3)+
            'YWPMKGFB'.repeat(2)).split(''),
    turn_limits: {
        2:{
            default:2,
            per_player:3,
            npc_stacks:[7,8,9,10],
            clear_bonus:3,
            star_thresholds:[8,17,26,34,43],
        },
        3:{
            default:2,
            per_player:2,
            npc_stacks:[7,8,9],
            clear_bonus:3,
            star_thresholds:[13,25,37,49,61],
        },
        4:{
            default:6,
            per_player:1,
            npc_stacks:[7,8],
            clear_bonus:1,
            star_thresholds:[16,32,48,64,80],
        },
        5:{
            default:5,
            per_player:1,
            npc_stacks:[7],
            clear_bonus:1,
            star_thresholds:[20,40,60,80,100],
        },
        6:{
            default:4,
            per_player:1,
            npc_stacks:[],
            clear_bonus:1,
            star_thresholds:[23,46,69,92,115],
        },
    }
}