export const firebaseConfig = {
  apiKey: "AIzaSyDWhExNdkYQ_TemW4Rp7K_tH3nS3wEw6Ls",
  authDomain: "games-eac51.firebaseapp.com",
  databaseURL: "https://games-eac51.firebaseio.com",
  projectId: "games-eac51",
  storageBucket: "games-eac51.appspot.com",
  messagingSenderId: "130501765958",
  appId: "1:130501765958:web:aeef0d74998de08094d347",
  measurementId: "G-R9MDXFXQL0"
};

export {firebaseConfig as default};